const Cdata = [
    {
        "id": 1,
        "Direct": [65, 27, 10, 41, 33],
        "House": [8, 9],
        "Ending": [1, 6]
    },
    {
        "id": 2,
        "Direct": [81, 48, 90, 71, 38],
        "House": [2, 4],
        "Ending": [7, 8]
    },
    {
        "id": 3,
        "Direct": [60, 43, 24, 12, 85],
        "House": [8, 2],
        "Ending": [7, 3]
    },
    {
        "id": 4,
        "Direct": [87, 52, 15, 1, 45],
        "House": [4, 5],
        "Ending": [3, 5]
    },
    {
        "id": 5,
        "Direct": [74, 59, 85, 67, 81],
        "House": [5, 7],
        "Ending": [4, 1]
    },
    {
        "id": 6,
        "Direct": [15, 37, 83, 43, 28],
        "House": [4, 3],
        "Ending": [6, 5]
    },
    {
        "id": 7,
        "Direct": [1, 78, 69, 47, 32],
        "House": [2, 4],
        "Ending": [2, 7]
    },
    {
        "id": 8,
        "Direct": [54, 76, 86, 14, 38],
        "House": [5, 7],
        "Ending": [5, 6]
    },
    {
        "id": 9,
        "Direct": [77, 1, 34, 65, 71],
        "House": [6, 3],
        "Ending": [0, 3]
    },
    {
        "id": 10,
        "Direct": [20, 17, 82, 93, 5],
        "House": [2, 0],
        "Ending": [3, 1]
    },
    {
        "id": 11,
        "Direct": [65, 87, 27, 99, 51],
        "House": [1, 7],
        "Ending": [9, 2]
    },
    {
        "id": 12,
        "Direct": [45, 32, 78, 26, 95],
        "House": [9, 5],
        "Ending": [2, 8]
    },
    {
        "id": 13,
        "Direct": [86, 61, 6, 77, 19],
        "House": [5, 6],
        "Ending": [4, 6]
    },
    {
        "id": 14,
        "Direct": [54, 29, 66, 48, 98],
        "House": [2, 8],
        "Ending": [8, 1]
    }, {
        "id": 15,
        "Direct": [76, 91, 39, 17, '00'],
        "House": [4, 3],
        "Ending": [7, 8]
    }, {
        "id": 16,
        "Direct": [59, 40, 35, 86, '02'],
        "House": [0, 4],
        "Ending": [2, 9]
    }, {
        "id": 17,
        "Direct": [36, 40, 32, 86, 54],
        "House": [3, 8],
        "Ending": [4, 1]
    }, {
        "id": 18,
        "Direct": [25, 80, 91, 14, 77],
        "House": [1, 9],
        "Ending": [6, 4]
    }, {
        "id": 19,
        "Direct": ['01', 78, 69, 47, 21],
        "House": [0, 2],
        "Ending": [7, 3]
    }, {
        "id": 20,
        "Direct": [25, 99, 13, 89, 58],
        "House": [4, 3],
        "Ending": [1, 3]
    }, {
        "id": 21,
        "Direct": [87, 51, 27, 72, 59],
        "House": [5, 7],
        "Ending": [4, 2]
    }, {
        "id": 22,
        "Direct": [12, 24, '01', 66, 39],
        "House": [3, 9],
        "Ending": [8, 6]
    }, {
        "id": 23,
        "Direct": [91, 66, 45, 35, 79],
        "House": [5, 7],
        "Ending": [5, 2]
    }, {
        "id": 24,
        "Direct": [67, 86, 51, 27, 19],
        "House": [1, 2],
        "Ending": [7, 8]
    }, {
        "id": 25,
        "Direct": [79, 99, 21, 62, 86],
        "House": [8, 2],
        "Ending": [9, 7]
    }, {
        "id": 26,
        "Direct": [95, 24, 69, 80, 38],
        "House": [3, 8],
        "Ending": [7, 8]
    }, {
        "id": 27,
        "Direct": [11, 36, 97, 61, 20],
        "House": [1, 6],
        "Ending": [1, 9]
    }, {
        "id": 28,
        "Direct": [12, 43, 56, 78, 27],
        "House": [3, 8],
        "Ending": [5, 6]
    }, {
        "id": 29,
        "Direct": [54, 76, 86, 14, 38],
        "House": [5, 7],
        "Ending": [0, 2]
    }, {
        "id": 30,
        "Direct": [55, 73, 91, 67, '02'],
        "House": [9, 0],
        "Ending": [1, 3]
    }, {
        "id": 31,
        "Direct": [25, 48, 31, 98, 10],
        "House": [2, 4],
        "Ending": [8, 5]
    }, {
        "id": 32,
        "Direct": [50, 67, 82, 93, 11],
        "House": [2, 4],
        "Ending": [7, 4]
    }, {
        "id": 33,
        "Direct": [33, 85, 47, 17, '01'],
        "House": [0, 4],
        "Ending": [1, 2]
    }, {
        "id": 34,
        "Direct": [70, 34, 58, 68, 21],
        "House": [2, 8],
        "Ending": [9, 6]
    }, {
        "id": 35,
        "Direct": [74, 13, 48, 99, '07'],
        "House": [0, 3],
        "Ending": [4, 7]
    }, {
        "id": 36,
        "Direct": [20, 37, 53, 72, 88],
        "House": [4, 5],
        "Ending": [5, 7]
    }, {
        "id": 37,
        "Direct": [76, 23, 48, 39, 17],
        "House": [3, 2],
        "Ending": [9, 8]
    }, {
        "id": 38,
        "Direct": [59, 65, 87, 95, 33],
        "House": [4, 0],
        "Ending": [7, 6]
    }, {
        "id": 39,
        "Direct": [46, 32, 77, 26, 15],
        "House": [1, 7],
        "Ending": [8, 9]
    }, {
        "id": 40,
        "Direct": [32, 43, 65, '00', 98],
        "House": [7, 6],
        "Ending": [4, 5]
    }, {
        "id": 41,
        "Direct": [45, 95, 24, 69, 37],
        "House": [2, 3],
        "Ending": [8, 9]
    }, {
        "id": 42,
        "Direct": [11, 99, 38, 71, 51],
        "House": [1, 3],
        "Ending": [1, 9]
    }, {
        "id": 43,
        "Direct": [47, 82, 93, 10, 29],
        "House": [2, 4],
        "Ending": [7, 8]
    }, {
        "id": 44,
        "Direct": [43, 76, 89, 23, 19],
        "House": [6, 9],
        "Ending": [7, 8]
    }, {
        "id": 45,
        "Direct": [36, 40, 38, 86, 50],
        "House": [2, 4],
        "Ending": [5, 6]
    }, {
        "id": 46,
        "Direct": [11, 36, 97, 61, 29],
        "House": [2, 4],
        "Ending": [9, 7]
    }, {
        "id": 47,
        "Direct": [78, 43, 65, 55, 72],
        "House": [7, 8],
        "Ending": [8, 3]
    }, {
        "id": 48,
        "Direct": [54, 76, 86, 64, 38],
        "House": [6, 4],
        "Ending": [9, 0]
    }, {
        "id": 49,
        "Direct": [23, 39, 43, 12, 67],
        "House": [3, 4],
        "Ending": [7, 8]
    }, {
        "id": 50,
        "Direct": [35, 61, 75, 99, 27],
        "House": [2, 4],
        "Ending": [9, 5]
    },
]

export { Cdata }


